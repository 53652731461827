import './Divider.scss';

interface DividerProps {
  id: number;
}

/**
 * Dotted lines that divide the video course components.
 */
function Divider(props: DividerProps) {
  return <div className={`divider position-${props.id}`}></div>;
}

export default Divider;
