import './PulseLoader.scss';

/**
 * Pulsing cirlce loader.
 */
function PulseLoader() {
  return <div className="pulse-loader"></div>;
}

export default PulseLoader;
