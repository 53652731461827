import React from 'react';

interface LinkedInProps {
  iconTitle: string;
}

const LinkedIn = (props: LinkedInProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-labelledby={props.iconTitle}
    >
      <path d="M4.842,2.592A2.3,2.3,0,1,1,2.546.315,2.284,2.284,0,0,1,4.842,2.592ZM.527,19.685V6.7H4.553V19.685Zm19.223-7.6v7.6H15.756V12.662c0-1.714-.7-2.8-2.2-2.8a2.22,2.22,0,0,0-2.19,1.467,3.309,3.309,0,0,0-.2,1.1v7.257H7.178V6.7h3.834V8.529a4.588,4.588,0,0,1,4.012-2.146C18.019,6.383,19.75,7.987,19.75,12.087Z" />
    </svg>
  );
};

export default LinkedIn;
