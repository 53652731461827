import './SnowFalling.scss';

/**
 * Snow falling.
 */
function SnowFalling() {
  return (
    <div className="snow-falling">
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
    </div>
  );
}

export default SnowFalling;
