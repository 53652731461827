import { useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { ReactComponent as CampCranium } from 'images/CampCranium.svg';
import { ReactComponent as WinterLandscapeDesktop } from 'images/winter-landscape.svg';
import { ReactComponent as WinterLandscapeMobile } from 'images/winter-landscape-mobile.svg';
import Badge from 'components/Badge/Badge';
import './Home.scss';

gsap.registerPlugin(ScrollTrigger);

/**
 * Home screen.
 */
function Home() {
  const ref = useRef<HTMLDivElement>(null);
  const triggerElement = '.trigger';
  const startPos = 'top';
  const endPos = 'bottom bottom';

  // Calculate vertical height: vh(number).
  const vh = (coef: number) => window.innerHeight * (coef / 100);

  gsap.defaults({
    ease: 'power1.inOut',
  });

  useEffect(() => {
    let ctx = gsap.context(() => {
      // Background.
      gsap.to('.home', {
        backgroundColor: '#1d9fda',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });

      // !MOBILE.
      gsap.to('.mobile #clouds', {
        opacity: 0,
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain1', {
        x: -30,
        y: 155,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain2', {
        x: -20,
        y: 155,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain3', {
        x: -5,
        y: 155,
        opacity: 0,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain4', {
        x: 5,
        y: 155,
        opacity: 0,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain5', {
        x: 15,
        y: 155,
        opacity: 0,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain6', {
        x: 20,
        y: 155,
        opacity: 0,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain7', {
        x: 30,
        y: 155,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.mobile #mountain8', {
        x: 35,
        y: 155,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });

      // !DESKTOP.

      // Clouds.
      /*
      gsap.to('.desktop #clouds', {
        y: -118,
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      */
      gsap.to('.desktop #cloud', {
        fill: '#ffffff',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.desktop #single-cloud', {
        fill: '#ffffff',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.desktop #dark-clouds', {
        opacity: 0,
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: vh(100) + startPos,
        },
      });
      gsap.to('.desktop #small-clouds', {
        opacity: 0,
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });

      // Left mountains.
      gsap.to('.desktop #mountain1', {
        x: -200,
        y: 132,
        scale: 0.9,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
          //markers: true,
        },
      });
      gsap.to('.desktop #mountain2', {
        x: -180,
        y: 132,
        scale: 0.7,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });

      // Middle mountains.
      gsap.to('.desktop #mountain3', {
        x: -130,
        y: 132,
        opacity: 0,
        scale: 0.7,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.desktop #mountain4', {
        x: -100,
        y: 132,
        opacity: 0,
        scale: 0.7,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.desktop #mountain5', {
        x: 100,
        y: 132,
        opacity: 0,
        scale: 0.7,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.desktop #mountain6', {
        x: 130,
        y: 132,
        opacity: 0,
        scale: 0.7,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });

      // Right mountains.
      gsap.to('.desktop #mountain7', {
        x: 250,
        y: 132,
        scale: 0.6,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });
      gsap.to('.desktop #mountain8', {
        x: 220,
        y: 132,
        scale: 1.2,
        transformOrigin: 'bottom',
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: endPos,
        },
      });

      // Camp Cranium logo.
      gsap.to('.logo', {
        opacity: 0,
        width: 280,
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: startPos,
          end: vh(100) + startPos,
        },
      });

      // Camp Cranium logo.
      gsap.to('.intro', {
        opacity: 1,
        scrollTrigger: {
          trigger: triggerElement,
          scrub: true,
          start: vh(100) + startPos,
          end: endPos,
        },
      });

      //
    }, ref);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={ref}>
      <div className="home">
        <div className="trigger"></div>
        <div className="content-height"></div>

        <div className="content">
          <div className="sticky">
            <div className="splash">
              <CampCranium className="logo" />
            </div>

            <div className="intro">
              <div className="text">
                <h1>Welcome, Campers!</h1>
                <p>
                  Introducing Camp Cranium, where we’re sharing “the gift of
                  knowledge” with you this holiday season as a thank you for
                  your support and inspiration throughout the year. Luckily,
                  neither sunscreen nor bug spray will be required for this
                  adventure!
                </p>
                <h3>How to enjoy the campsite:</h3>
                <p>
                  Watch our team’s super-quick video “present”-ations below. For
                  each one you watch, you’ll earn a spiffy badge. As you do,
                  you’ll be sure to learn new skills along the way. And if you
                  complete all six videos, you’ll unlock a bonus badge at base
                  camp!
                </p>
              </div>
              <div className="badge-banner">
                <Badge id={1} />
                <Badge id={2} />
                <Badge id={3} />
                <Badge id={4} />
                <Badge id={5} />
                <Badge id={6} />
              </div>
            </div>
          </div>
        </div>

        <div className="content landscape">
          <div className="sticky">
            <WinterLandscapeMobile className="mobile" />
            <WinterLandscapeDesktop className="desktop" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
