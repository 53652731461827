import { useStore } from 'store/store';

import CloseButton from 'components/CloseButton/CloseButton';
import PulseLoader from 'components/PulseLoader/PulseLoader';
import './TikTokModal.scss';

interface TikTokModalProps {
  close: () => void;
  visible: boolean;
}

/**
 * Modal with iframe to embeded TikTok videos.
 */
function TikTokModal(props: TikTokModalProps) {
  const state = useStore()[0];

  return (
    <div className={`tiktok-modal ${props.visible ? 'is-active' : ''}`}>
      <div className="inner">
        <CloseButton onClick={props.close} />

        <div className="tiktok">
          {state.tiktokVideoURL ? (
            <iframe
              src={state.tiktokVideoURL}
              allow="encrypted-media"
              title="TikTok video"
              scrolling="no"
            ></iframe>
          ) : (
            ''
          )}
          <PulseLoader />
        </div>
      </div>
      <div className="overlay" onClick={props.close}></div>
    </div>
  );
}

export default TikTokModal;
