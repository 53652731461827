import { useStore } from 'store/store';

import Facebook from 'icons/Branding/Facebook';
import Instagram from 'icons/Branding/Instagram';
import LinkedIn from 'icons/Branding/LinkedIn';
import Twitter from 'icons/Branding/Twitter';
import YouTube from 'icons/Branding/YouTube';
import './MenuDrawer.scss';

/**
 * Drawer that opens from the Hamburger Menu.
 */
function MenuDrawer() {
  const state = useStore()[0];

  return (
    <nav role="navigation">
      <div
        id="menu-drawer"
        className={`${state.menuDrawerVisible ? 'is-active' : ''}`}
      >
        <div className="links-wrapper">
          <div className="links">
            <div className="link h0">
              <a href="https://tricomb2b.com/team">Team</a>
            </div>
            <div className="link h0">
              <a href="https://tricomb2b.com/portfolio">Portfolio</a>
            </div>
            <div className="link h0">
              <a href="https://tricomb2b.com/what-expect">What to Expect</a>
            </div>
            <div className="link h0">
              <a href="https://tricomb2b.com/resources">Resources</a>
            </div>
            <div className="link h0">
              <a href="https://tricomb2b.com/careers">Careers</a>
            </div>
            <div className="link h0">
              <a href="https://tricomb2b.com/contact">Contact</a>
            </div>
          </div>
        </div>

        <div className="social-wrapper">
          <div className="links">
            <div className="link">
              <a
                href="https://www.facebook.com/tricomb2b"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="TriComB2B’s Facebook profile"
              >
                <Facebook iconTitle="facebook" />
              </a>
            </div>
            <div className="link">
              <a
                href="https://www.linkedin.com/company/tricomb2b"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="TriComB2B’s LinkedIn profile"
              >
                <LinkedIn iconTitle="linkedin" />
              </a>
            </div>
            <div className="link">
              <a
                href="https://twitter.com/tricomb2b"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="TriComB2B’s Twitter profile"
              >
                <Twitter iconTitle="twitter" />
              </a>
            </div>
            <div className="link">
              <a
                href="https://www.instagram.com/tricomb2b"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="TriComB2B’s Instagram profile"
              >
                <Instagram iconTitle="instagram" />
              </a>
            </div>
            <div className="link">
              <a
                href="https://www.youtube.com/user/TriComB2Bvideo"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="TriComB2B’s YouTube profile"
              >
                <YouTube iconTitle="youtube" />
              </a>
            </div>
          </div>
        </div>

        <div className="logo-wrapper">
          <div className="bracket-text basic-brackets">B2Be.</div>
        </div>
      </div>
    </nav>
  );
}

export default MenuDrawer;
