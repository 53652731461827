import React from 'react';

interface InstagramProps {
  iconTitle: string;
}

const Instagram = (props: InstagramProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-labelledby={props.iconTitle}
    >
      <path d="M10,2.007c2.6,0,2.912.01,3.94.057a5.4,5.4,0,0,1,1.81.335,3.013,3.013,0,0,1,1.121.73A3.013,3.013,0,0,1,17.6,4.25a5.4,5.4,0,0,1,.335,1.81c.047,1.028.057,1.337.057,3.94s-.01,2.912-.057,3.94a5.4,5.4,0,0,1-.335,1.81A3.236,3.236,0,0,1,15.75,17.6a5.4,5.4,0,0,1-1.81.335c-1.028.047-1.336.057-3.94.057s-2.912-.01-3.94-.057A5.4,5.4,0,0,1,4.25,17.6a3.013,3.013,0,0,1-1.121-.73A3.013,3.013,0,0,1,2.4,15.75a5.4,5.4,0,0,1-.335-1.81C2.017,12.912,2.007,12.6,2.007,10s.01-2.912.057-3.94A5.4,5.4,0,0,1,2.4,4.25a3.013,3.013,0,0,1,.73-1.121A3.013,3.013,0,0,1,4.25,2.4a5.4,5.4,0,0,1,1.81-.335C7.088,2.017,7.4,2.007,10,2.007M10,.25C7.352.25,7.02.261,5.98.309A7.154,7.154,0,0,0,3.613.762,4.777,4.777,0,0,0,1.886,1.886,4.777,4.777,0,0,0,.762,3.613,7.154,7.154,0,0,0,.309,5.98C.261,7.02.25,7.352.25,10s.011,2.98.059,4.02a7.154,7.154,0,0,0,.453,2.367,4.777,4.777,0,0,0,1.124,1.727,4.777,4.777,0,0,0,1.727,1.124,7.154,7.154,0,0,0,2.367.453c1.04.048,1.372.059,4.02.059s2.98-.011,4.02-.059a7.154,7.154,0,0,0,2.367-.453,4.979,4.979,0,0,0,2.851-2.851,7.154,7.154,0,0,0,.453-2.367c.048-1.04.059-1.372.059-4.02s-.011-2.98-.059-4.02a7.154,7.154,0,0,0-.453-2.367,4.777,4.777,0,0,0-1.124-1.727A4.777,4.777,0,0,0,16.387.762,7.154,7.154,0,0,0,14.02.309C12.98.261,12.648.25,10,.25Z" />
      <path d="M10,4.993A5.007,5.007,0,1,0,15.007,10,5.007,5.007,0,0,0,10,4.993Zm0,8.257A3.25,3.25,0,1,1,13.25,10,3.25,3.25,0,0,1,10,13.25Z" />
      <circle cx="15.205" cy="4.795" r="1.17" />
    </svg>
  );
};

export default Instagram;
