import React from 'react';

interface YouTubeProps {
  iconTitle: string;
}

const YouTube = (props: YouTubeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-labelledby={props.iconTitle}
    >
      <path d="M19.343,5.276a2.452,2.452,0,0,0-1.724-1.735C16.1,3.131,10,3.131,10,3.131s-6.1,0-7.619.41A2.452,2.452,0,0,0,.657,5.276,25.735,25.735,0,0,0,.25,10a25.735,25.735,0,0,0,.407,4.724,2.452,2.452,0,0,0,1.724,1.735c1.521.41,7.619.41,7.619.41s6.1,0,7.619-.41a2.452,2.452,0,0,0,1.724-1.735A25.735,25.735,0,0,0,19.75,10,25.735,25.735,0,0,0,19.343,5.276ZM8.006,12.9V7.1L13.1,10Z" />
    </svg>
  );
};

export default YouTube;
