import Facebook from 'icons/Branding/Facebook';
import LinkedIn from 'icons/Branding/LinkedIn';
import AddToStories from 'images/addtostoriesicon.png';
import './SocialShare.scss';

/**
 * Social media sharing links and icons.
 */
function SocialShare() {
  return (
    <div className="social-share">
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=https://tricomb2b.com/CampCranium&t=Camp Cranium&quote="
        target="_blank"
        rel="noreferrer"
      >
        <Facebook iconTitle="facebook-share" />
      </a>
      <a
        href="https://www.linkedin.com/sharing/share-offsite/?url=https://tricomb2b.com/CampCranium"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedIn iconTitle="linkedin-share" />
      </a>
      <a
        href="https://tricomb2b.com/CampCranium/images/Badge_Sash_Social-v1-03.jpg"
        download
      >
        <img src={AddToStories} alt="Download for Instagram Stories" />
      </a>
    </div>
  );
}

export default SocialShare;
