import { useEffect } from 'react';
import { useStore } from 'store/store';

import Courses from 'features/Courses/Courses';
import Champion from 'features/Champion/Champion';
import Footer from 'components/Footer/footer';
import Home from 'features/Home/Home';
import SnowFalling from 'components/SnowFalling/SnowFalling';
import './Main.scss';

/**
 * Main panel of the app. Where most of the action is.
 */
function Main() {
  const dispatch = useStore()[1];

  // Init data.
  useEffect(() => {
    dispatch('LOAD_CAMP');
  }, [dispatch]);

  return (
    <div className="main">
      <Home />
      <Courses />
      <Champion />
      <Footer />
      <SnowFalling />
    </div>
  );
}

export default Main;
