import { initStore } from './store';
import TagManager from 'react-gtm-module';

/**
 * Creates the store for managing state shared across the app.
 */
const configureGlobalStore = () => {
  const actions = {
    /**
     * Load data from localstorage.
     */
    LOAD_CAMP: (state: any) => {
      const loadCampComplete = JSON.parse(
        localStorage.getItem('championBadge') || 'false',
      );
      const loadChampionBadge = JSON.parse(
        localStorage.getItem('championBadge') || 'false',
      );
      const loadSkillBadges = JSON.parse(
        localStorage.getItem('skillBadges') ||
          '[false,false,false,false,false,false]',
      );

      return {
        ...state,
        skillBadges: loadSkillBadges,
        championBadge: loadChampionBadge,
        campComplete: loadCampComplete,
      };
    },

    /**
     * Set the current video Skill Badge as complete.
     * Check if the Champion Badge has been earned.
     */
    SET_BADGE_COMPLETE: (state: any) => {
      if (state.skillBadges[state.currentVideoID - 1] === true) {
        return state;
      }

      const badgeTitles = [
        'SaladBadge',
        'PokerBadge',
        'CrochetBadge',
        'CookieBadge',
        'DanceBadge',
        'BikeBadge',
      ];

      const updateSkillBadges = state.skillBadges;
      updateSkillBadges[state.currentVideoID - 1] = true;
      localStorage.setItem('skillBadges', JSON.stringify(updateSkillBadges));

      const updateChampionBadge = updateSkillBadges.every(
        (value: boolean) => value === true,
      );
      localStorage.setItem(
        'championBadge',
        JSON.stringify(updateChampionBadge),
      );

      TagManager.dataLayer({
        dataLayer: {
          event: 'earned_badge',
          badge: badgeTitles[state.currentVideoID - 1],
        },
      });

      if (updateChampionBadge) {
        TagManager.dataLayer({
          dataLayer: { event: 'earned_badge', badge: 'Champion' },
        });
      }

      return {
        ...state,
        badgeComplete: updateSkillBadges,
        championBadge: updateChampionBadge,
      };
    },

    /**
     * Track if camp is complete. Used to remove animation delays on reload.
     */
    SET_CAMP_COMPLETE: (state: any) => {
      localStorage.setItem('campComplete', JSON.stringify(true));

      return {
        ...state,
        campComplete: true,
      };
    },

    /**
     * Track the current video being played.
     */
    SET_CURRENT_VIDEO_ID: (state: any, id: number) => ({
      currentVideoID: id,
    }),

    /**
     * Video URL to use in the TikTok modal.
     */
    SET_TIKTOK_VIDEO_URL: (state: any, url: string) => ({
      tiktokVideoURL: url,
    }),

    /**
     * Flip state for the Menu Drawer visibility.
     */
    TOGGLE_MENU_DRAWER: (state: any) => ({
      menuDrawerVisible: !state.menuDrawerVisible,
    }),

    /**
     * Flip state for the TikTok Modal visibility.
     */
    TOGGLE_TIKTOK_MODAL: (state: any) => ({
      tiktokModalActive: !state.tiktokModalActive,
    }),
  };

  initStore(actions, {
    campComplete: false,
    championBadge: false,
    currentVideoID: '',
    menuDrawerVisible: false,
    skillBadges: [],
    tiktokVideoURL: '',
    tiktokModalActive: false,
  });
};

export default configureGlobalStore;
