import React from 'react';

import Hamburger from 'components/Branding/Hamburger/Hamburger';
import Logo from 'components/Branding/Logo/Logo';
import TextLogo from 'components/Branding/TextLogo/TextLogo';
import './Header.scss';

/**
 * Header: holds the logo and hamburger menu.
 */
function Header() {
  return (
    <header role="banner">
      <Logo />
      <TextLogo />
      <Hamburger />
    </header>
  );
}

export default Header;
