import { useStore } from 'store/store';

import Badge from 'components/Badge/Badge';
import SnowyBox from 'components/SnowyBox/SnowyBox';
import './VideoCourse.scss';

interface VideoCourseProps {
  id: number;
  copy: string;
  locked?: boolean;
  title: string;
  snowPosition: string[];
  videoPosition: 'left' | 'right';
  tiktokVideo: string;
}

/**
 * Block containing the Video, info, award, background.
 */
function VideoCourse(props: VideoCourseProps) {
  const dispatch = useStore()[1];
  const imgSrc = props.locked
    ? `images/thumb${props.id}-locked.png`
    : `images/thumb${props.id}.jpg`;

  // Open the TikTok Modal.
  function openTiktokModal() {
    dispatch('SET_TIKTOK_VIDEO_URL', props.tiktokVideo);
    dispatch('SET_CURRENT_VIDEO_ID', props.id);
    dispatch('TOGGLE_TIKTOK_MODAL');
  }

  return (
    <div
      className={`video-course ${props.videoPosition} ${
        props.locked ? 'locked' : ''
      }`}
    >
      <div className="content">
        <div className="video">
          <div className="thumbnail" onClick={openTiktokModal}>
            <img src={imgSrc} alt="" width="328" height="583" />
          </div>
        </div>
        <div className="info">
          <div className="copy">
            <h2>{props.title}</h2>
            <p>{props.copy}</p>
          </div>
          <div className="badge-wrapper">
            <Badge id={props.id} />
          </div>
        </div>
      </div>
      <SnowyBox
        snowLocation1={props.snowPosition[0]}
        snowLocation2={props.snowPosition[1]}
      />
    </div>
  );
}

export default VideoCourse;
