import React from 'react';

import './Logo.scss';

/**
 * Logo.
 */
function Logo() {
  return (
    <div id="logo">
      <a
        href="https://tricomb2b.com"
        aria-label="logo link"
        target="_blank"
        rel="noreferrer"
      >
        TricomB2B Homepage
      </a>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <path d="M41.7,16.7H29.2v25H20.9v-25H8.3V8.3H41.6v8.4ZM33.3,29.2V45.9H16.7V29.2H12.5V50h25V29.2ZM22.9,4.2V0H0V25H16.7V20.8H4.2V4.2ZM27.1,0V4.2H45.8V20.9H33.3V25H50V0Z"></path>
      </svg>
    </div>
  );
}

export default Logo;
