import './SnowyBox.scss';

interface SnowyBoxProps {
  snowLocation1: string;
  snowLocation2: string;
}

/**
 * Rounded grey box. The background for the VideoCourse component.
 * Desktop view shows two piles of snow in different location.
 */
function SnowyBox(props: SnowyBoxProps) {
  return (
    <div className="snowy-box">
      <div className={`snow ${props.snowLocation1}`}></div>
      <div className={`snow ${props.snowLocation2}`}></div>
    </div>
  );
}

export default SnowyBox;
