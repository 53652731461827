import React from 'react';

interface MenuProps {
  iconTitle: string;
}

const Menu = (props: MenuProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Hamburger Menu Icon</title>
      <circle className="circle" cx="25" cy="25" r="24" />
      <rect id="bar-3" className="lines" width="25" height="1" />
      <rect id="bar-2" className="lines" width="25" height="1" />
      <rect id="bar-1" className="lines" width="25" height="1" />
    </svg>
  );
};

export default Menu;
