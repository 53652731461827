import { useStore } from 'store/store';

import './Badge.scss';

interface BadgeProps {
  id: number;
  preview?: boolean;
}

/**
 * Displays a badge or an outline if the video has not been viewed yet.
 */
function Badge(props: BadgeProps) {
  const state = useStore()[0];

  return (
    <div
      className={`badge badge-${props.id} ${
        state.skillBadges[props.id - 1] || props.preview ? 'is-complete' : ''
      }`}
    ></div>
  );
}

export default Badge;
