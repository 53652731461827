import React from 'react';

interface TwitterProps {
  iconTitle: string;
}

const Twitter = (props: TwitterProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-labelledby={props.iconTitle}
    >
      <path d="M13.845,1.873a4.1,4.1,0,0,1,3,1.3,8.212,8.212,0,0,0,2.606-1,4.109,4.109,0,0,1-1.8,2.27A8.221,8.221,0,0,0,20,3.8a8.352,8.352,0,0,1-2.047,2.124v.531A11.578,11.578,0,0,1,6.289,18.127,11.6,11.6,0,0,1,0,16.283a8.09,8.09,0,0,0,.978.058,8.236,8.236,0,0,0,5.1-1.756,4.106,4.106,0,0,1-3.832-2.85,4.082,4.082,0,0,0,1.852-.071A4.106,4.106,0,0,1,.8,7.641V7.589a4.094,4.094,0,0,0,1.86.513A4.111,4.111,0,0,1,1.391,2.624,11.654,11.654,0,0,0,9.849,6.911a4.111,4.111,0,0,1,4-5.038Z" />
    </svg>
  );
};

export default Twitter;
