import React from 'react';

interface FacebookProps {
  iconTitle: string;
}

const Facebook = (props: FacebookProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-labelledby={props.iconTitle}
    >
      <path d="M15.015.432V3.455c-.367-.02-1.143-.064-2-.064a1.44,1.44,0,0,0-1.629,1.376V7.5h3.628l-.557,3.4H11.391V19.75H7.975V10.892H4.981V7.5H7.975V4.722a4.561,4.561,0,0,1,1.1-3.253A5,5,0,0,1,12.447.25,18.582,18.582,0,0,1,15.015.432Z" />
    </svg>
  );
};

export default Facebook;
