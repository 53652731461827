import { useStore } from 'store/store';

import TricomB2B from 'icons/Branding/TricomB2B';
import './TextLogo.scss';

/**
 * Header: holds the logo and hamburger menu.
 */
function TextLogo() {
  const state = useStore()[0];

  return (
    <div
      id="text-logo"
      className={`${state.menuDrawerVisible ? 'is-active' : ''}`}
    >
      <TricomB2B iconTitle="TricomB2B-text-logo" />
    </div>
  );
}

export default TextLogo;
