import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import reportWebVitals from './reportWebVitals';

import configureGlobalStore from 'store/globalStore';

import Header from 'features/Header/Header';
import Main from 'features/Main/Main';
import MenuDrawer from 'features/MenuDrawer/MenuDrawer';
import 'styles/app.scss';

/**
 * Uncomment this section once we have a GTM container ID.
 */
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
};

TagManager.initialize(tagManagerArgs);

configureGlobalStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="app-wrap">
      <Header />
      <MenuDrawer />
      <Main />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
