import { useStore } from 'store/store';

import Divider from 'components/Divider/Divider';
import TikTokModal from 'components/TikTokModal/TikTokModal';
import VideoCourse from 'features/VideoCourse/VideoCourse';
import './Courses.scss';

/**
 * All of the video sections.
 */
function Courses() {
  const [state, dispatch] = useStore();

  // Close the TikTok Modal.
  function closeTiktokModal() {
    dispatch('TOGGLE_TIKTOK_MODAL');
    // Give modal time to close so you don't see the video dissapear.
    setTimeout(() => {
      dispatch('SET_TIKTOK_VIDEO_URL', '');
      dispatch('SET_BADGE_COMPLETE');
    }, 300);
  }

  return (
    <div className="courses">
      <VideoCourse
        id={4}
        title="Lovin’ From the Oven!"
        copy="Nothing evokes the spirit of the season quite like the smell of something yummy baking in a kitchen. And with Lauren’s virtually fool proof Jam Thumbprint Cookies, you can get your holiday “cooking” done in minutes!"
        tiktokVideo="https://tiktok.com/embed/7307688137789082923"
        videoPosition="left"
        snowPosition={['left-bottom', 'right-top-corner']}
      />

      <Divider id={1} />

      <VideoCourse
        id={2}
        title="Ho Ho Hold ’Em!"
        copy="In this episode, Todd teaches you the basics of Texas Hold ’em, one of the world’s most popular poker games. In just two minutes, you’ll learn everything you need to know to ante up with your auntie, uncle or any other relative!"
        tiktokVideo="https://tiktok.com/embed/7307693572646505771"
        videoPosition="right"
        snowPosition={['top-middle-left', 'right-bottom-corner']}
      />

      <Divider id={2} />

      <VideoCourse
        id={3}
        title="Crochet Like Your Grandma (or Maybe Your Great-Grandma)!"
        copy="Handmade gifts are often the most meaningful, so Lindsay is working hard to keep the art of crocheting alive. Today she’ll show you how to create a “granny square” — a great beginner project that no machine can make!"
        tiktokVideo="https://tiktok.com/embed/7307296360796130602"
        videoPosition="left"
        snowPosition={['left-top', 'top-middle-right']}
      />

      <Divider id={3} />

      <VideoCourse
        id={1}
        title="Mmm, Mmm, Greens!"
        copy="Celebrate the holidays — or any other occasion — with Stacey’s super-simple Microgreens Taco Salad. Just toss a few ingredients into a bowl and mix. It’s that easy to make this savory dish (and score the Green Thumbs Up badge)."
        tiktokVideo="https://tiktok.com/embed/7307686079681940778"
        videoPosition="right"
        snowPosition={['left-top-corner', 'right-bottom']}
      />

      <Divider id={4} />

      <VideoCourse
        id={6}
        title="C’mon, It’s Lovely Weather for a … Bike Ride!"
        copy="Whether you’re riding over the river and through the woods or just around the neighborhood, you don’t need a floppy wheel slowing you down. In under two minutes flat, Scott will show you how to change a bike tire!"
        tiktokVideo="https://tiktok.com/embed/7307698222368165162"
        videoPosition="left"
        snowPosition={['left-middle', 'top-middle-left-wide']}
      />

      <Divider id={5} />

      <VideoCourse
        id={5}
        title="Get Dancing Merrily in the New Old-Fashioned Way!"
        copy="If you feel like you’ve spent too much time behind a desk this year, Tom’s gift will get you up and moving! Learn to cut a rug with the basics of the Charleston, one of the most popular dances of the 1920s."
        tiktokVideo="https://tiktok.com/embed/7307679614124477739"
        videoPosition="right"
        snowPosition={['left-top-bigger', 'top-middle-right2']}
      />

      <TikTokModal close={closeTiktokModal} visible={state.tiktokModalActive} />
    </div>
  );
}

export default Courses;
