import { useStore } from 'store/store';

import './Hamburger.scss';
import Menu from 'icons/Branding/Menu';

/**
 * Hamburger menu button.
 */
function Hamburger() {
  const [state, dispatch] = useStore();

  /**
   * Toggle the Menu Drawer.
   */
  function toggleMenuDrawer() {
    dispatch('TOGGLE_MENU_DRAWER');
  }

  return (
    <div
      id="hamburger"
      className={`${state.menuDrawerVisible ? 'is-active' : ''}`}
      onClick={toggleMenuDrawer}
    >
      <Menu iconTitle="hamburger-menu" />
    </div>
  );
}

export default Hamburger;
