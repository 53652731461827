import SocialShare from 'components/SocialShare/SocialShare';
import { ReactComponent as CampCranium } from 'images/CampCranium.svg';
import { ReactComponent as SnowHills } from 'images/snow-hills.svg';
import './Footer.scss';

/**
 * Footer.
 */
function Footer() {
  return (
    <div className="footer">
      <div className="content">
        <p>
          Share your progress on the Camp Cranium trail and "the gift of
          knowledge" with your fellow explorers!
        </p>

        <SocialShare />

        <CampCranium className="logo" />
      </div>
      <SnowHills className="snow-hills" />
    </div>
  );
}

export default Footer;
