import { useEffect } from 'react';
import { useStore } from 'store/store';

import WinterLandscape from 'images/winter-landscape-end.svg';
import './Champion.scss';

/**
 * Champion section.
 */
function Champion() {
  const [state, dispatch] = useStore();

  //
  useEffect(() => {
    if (state.championBadge && !state.campComplete) {
      const championElement = document.getElementById('champion');
      if (!championElement) return;

      // Scroll to Champion badge.
      setTimeout(() => {
        championElement.scrollIntoView({
          behavior: 'smooth',
        });
        setTimeout(() => {
          dispatch('SET_CAMP_COMPLETE');
        }, 4000);
      }, 2600);
    }
  }, [dispatch, state.campComplete, state.championBadge]);

  return (
    <div
      id="champion"
      className={`champion ${state.championBadge ? 'is-active' : ''} ${
        state.campComplete ? 'is-complete' : ''
      }`}
    >
      <div className="content">
        <h2>Thanks for Playing!</h2>
        <p>We wish you the happiest of holiday seasons!</p>
      </div>
      <div className="graphics">
        <div className="award">
          <div className="image">
            <div className="overlay"></div>
          </div>
          <div className="shadow"></div>
        </div>
        <img src={WinterLandscape} width="1332" height="690" alt="" />
      </div>
    </div>
  );
}

export default Champion;
